import './style.css';
import './custom.css';
import 'flowbite/dist/flowbite.js';
import './sidebar';
// import './charts';
import './dark-mode';

// Additional JS files
// import './map';
// import './ui-controls';
// import './camera';